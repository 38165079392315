<template>
  <div class="row no-gutters p-md-3">
    <div class="col-md-4 col-xl-3 col-12">
      <div class="p-2">
        <div class="m-md-2 border-bottom">
          <div class="image-upload" @change="onChangeImage">
            <label for="file-input">
              <v-icon class="uploadImage">$imageIcon</v-icon>
            </label>

            <input
              class="selectFile"
              id="file-input"
              type="file"
              accept="image/*"
            />
          </div>
          <img
            v-if="item.imageUrl"
            :src="item.imageUrl"
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
          />
          <img
            v-else-if="this.$data.dataImage2"
            :src="this.$data.dataImage2"
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
          />
          <img
            v-else
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
            src="@/assets/images/img_avatar.png"
            alt="Alternative Text"
          />

          <div class="mb-2">
            <h4 class="text-center">{{ label.name[language] }}</h4>
            <h6 class="text-center">{{ label.position[language] }}</h6>
          </div>
        </div>

        <div>
          <v-btn @click="onClickPermission" class="w-100" color="primary"
            ><v-icon style="font-size: 1rem" class="mr-2">fa-pen</v-icon
            >{{ $t("manage_license.name") }}</v-btn
          >
        </div>

        <div
          class="border-top border-bottom py-10 px-3"
          style="overflow-x: auto"
        >
          <div class="text-center h4 my-3">
            {{ $t("contact_information.contact") }}
            <v-icon
              @click="onClickSocialContact"
              style="font-size: 1rem"
              class="ml-2 cursor-pointer"
              >fa-edit</v-icon
            >
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.phone") }}</div>
            <div class="col-8">
              {{ socialContactForm.account_information_phone || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.email") }}</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_email || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Facebook</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_facebook || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">LINE id</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_line || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Instagram</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_instagram || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Youtube</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_youtube || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">twitter</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_twitter || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.website") }}</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_website || "-" }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Fax</div>
            <div class="col-8">
              {{ socialContactForm.account_social_contact_fax || "-" }}
            </div>
          </div>
        </div>
        <!--&& this.$data.isPlayerRoute"-->
        <router-link
          v-if="isEditable && !isAddRoute"
          :to="`/${this.$data.isPlayerRouteName}/curriculumvitae/${this.accountId}`"
        >
          <v-btn color="primary" class="col-12 mt-5">
            <v-icon left dark> mdi-cloud-upload </v-icon>
            {{ $t("curriculumn.title") }}</v-btn
          >
        </router-link>
        <div class="text-center my-3 font-weight-bolder">
          {{ $t("public.status") }}
        </div>
        <div class="row border-bottom py-3">
          <div class="col-6">
            <checkbox
              :checked="accountForm.active"
              :disabled="!isEditable"
              v-model="accountForm.active"
            >
              <span
                v-if="accountForm.active"
                class="input-box"
                slot="input-box"
              >
                <v-icon>fa-check</v-icon>
              </span>
              <span v-if="accountForm.active">{{ $t("public.public") }}</span>
              <span v-else>{{ $t("public.no_public") }}</span>
            </checkbox>
          </div>
          <div class="col-6">
            <div>
              <label> {{ $t("public.create_date") }} </label>
            </div>
            <div>
              <label class="text-primary">
                {{ accountForm.createdAt }}
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div>
              <label> {{ $t("public.create_by") }} </label>
            </div>
            <div>
              <label style="word-break: break-all">
                {{ accountForm.updatedBy }}
              </label>
            </div>
          </div>
          <div class="col-6">
            <div>
              <label> {{ $t("public.update_date") }} </label>
            </div>
            <div>
              <label class="text-primary">
                {{ accountForm.updatedAt }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8 col-12 tab1-border-left">
      <!-- ข้อมูลรหัสประจำตัว -->
      <v-form ref="formRef">
        <div>
          <div class="border-bottom p-2">
            <div>
              <h4 class="text-primary">
                {{ $t("player.personal_info.identity_info") }}
              </h4>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      label.accountCode[language]
                    }}</label>
                    <v-text-field
                      :placeholder="label.accountCode[language]"
                      v-model="accountForm.account_information_code_personnel"
                      dense
                      outlined
                      :readonly="!isEditable"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      label.type[language]
                    }}</label>
                    <v-radio-group
                      active-class="active-radio"
                      v-model="accountForm.account_information_type_personnel"
                      row
                      dense
                      :readonly="!isEditable"
                    >
                      <v-radio
                        on-icon="fa-circle"
                        :label="label.thai[language]"
                        value="THAI"
                      ></v-radio>
                      <v-radio
                        on-icon="fa-circle"
                        :label="label.foregin[language]"
                        value="FOREIGN"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("player.personal_info.id_card")
                    }}</label>
                    <v-text-field
                      dense
                      outlined
                      :rules="citizenRule"
                      :readonly="!isEditable"
                      :placeholder="$t('player.personal_info.id_card')"
                      v-model="accountForm.account_information_citizen_id"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("player.personal_info.passport_num")
                    }}</label>
                    <v-text-field
                      dense
                      outlined
                      :rules="passportRules"
                      :readonly="!isEditable"
                      v-model="accountForm.account_information_passport_id"
                      :placeholder="$t('player.personal_info.passport_num')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ข้อมูลส่วนบุคคล -->
          <div class="p-2 border-bottom">
            <div>
              <h4 class="text-primary">
                {{ $t("player.personal_info.personal") }}
              </h4>
            </div>
            <div>
              <form class="needs-validation" novalidate="">
                <div class="row">
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.first_name_th")
                      }}</label>
                      <v-text-field
                        v-model="accountForm.account_information_firstname_th"
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('player.personal_info.first_name_th')"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.last_name_th")
                      }}</label>
                      <v-text-field
                        v-model="accountForm.account_information_lastname_th"
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('player.personal_info.last_name_th')"
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.first_name_en")
                      }}</label>
                      <v-text-field
                        v-model="accountForm.account_information_firstname_en"
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('player.personal_info.first_name_en')"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.last_name_en")
                      }}</label>
                      <v-text-field
                        v-model="accountForm.account_information_lastname_en"
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('player.personal_info.last_name_en')"
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.nickname_th")
                      }}</label>
                      <v-text-field
                        v-model="accountForm.account_information_nickname"
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('player.personal_info.nickname_th')"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.nickname_en")
                      }}</label>
                      <v-text-field
                        v-model="accountForm.account_information_nickname_en"
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('player.personal_info.nickname_en')"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.dob")
                      }}</label>
                      <v-menu
                        ref="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            :value="birthDayFormat"
                            dense
                            outlined
                            readonly
                            hide-details
                            :placeholder="$t('player.personal_info.dob')"
                            append-icon="fa-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          min="1950-01-01"
                          :max="maxSelectDate"
                          :readonly="!isEditable"
                          locale="us-us"
                          v-model="accountForm.account_information_dob"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name"
                        >{{ $t("player.personal_info.age") }}
                      </label>
                      <v-text-field
                        disabled
                        :value="age"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('player.personal_info.age')"
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.gender")
                      }}</label>
                      <v-select
                        :items="gender"
                        item-value="value"
                        :item-text="'text_' + language"
                        v-model="accountForm.account_information_gender"
                        :readonly="!isEditable"
                        :placeholder="$t('normal.please_select')"
                        dense
                        outlined
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.religion")
                      }}</label>
                      <v-select
                        :placeholder="$t('normal.please_select')"
                        v-model="accountForm.account_information_religion"
                        :readonly="!isEditable"
                        item-value="value"
                        :item-text="'text_' + language"
                        :items="religion"
                        dense
                        outlined
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.ethnicity")
                      }}</label>
                      <v-autocomplete
                        v-model="accountForm.account_information_race"
                        :readonly="!isEditable"
                        :placeholder="$t('normal.please_select')"
                        :items="countries"
                        :item-text="'country_native1_name_' + language"
                        item-value="country_native1_name_th"
                        dense
                        outlined
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div class="col-md-3 col-6">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.nationality")
                      }}</label>
                      <v-autocomplete
                        v-model="accountForm.account_information_nationality"
                        :readonly="!isEditable"
                        :items="countries"
                        :item-text="'country_native1_name_' + language"
                        item-value="country_native1_name_th"
                        :placeholder="$t('normal.please_select')"
                        dense
                        outlined
                      ></v-autocomplete>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!-- ที่อยู่ตามบัตร -->
          <div class="p-2 border-bottom">
            <div>
              <h4 class="text-primary">
                {{ $t("address.player_address_citizen_th") }}
              </h4>
            </div>
            <div>
              <Address :address.sync="accountForm.account_region_citizen" />
            </div>
            <!-- ที่อยู่ติดต่อปัจจุบัน -->
            <div>
              <div class="row">
                <div class="col-4 col-md-2">
                  <h4 class="text-primary">
                    {{ $t("address.address_current") }}
                  </h4>
                </div>
                <div class="col-8 col-md-10">
                  <span
                    class="
                      text-decoration-underline text-primary
                      cursor-pointer
                    "
                    @click="copyAddressToCurrent"
                    >{{ $t("address.copy_address") }}</span
                  >
                </div>
              </div>
            </div>
            <div>
              <Address :address.sync="accountForm.account_region_current" />
            </div>
          </div>

          <div class="p-2">
            <div class="d-flex justify-content-between">
              <h4 class="text-primary">
                {{ $t("player.personal_info.relationship_info") }}
              </h4>
              <v-btn
                elevation="2"
                class="btn-primary"
                :disabled="!isEditable"
                @click="() => onShowRelationModal()"
              >
                + {{ $t("player.personal_info.add_relationship") }}
              </v-btn>
            </div>

            <div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("player.personal_info.relation") }}
                      </th>
                      <th class="text-left">
                        {{ $t("player.personal_info.name-surname") }}
                      </th>
                      <th class="text-left">
                        {{ $t("player.personal_info.phone") }}
                      </th>
                      <th class="text-left">
                        {{ $t("player.personal_info.contact-email") }}
                      </th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="relation.length > 0">
                      <tr v-for="(item, idx) in relation" :key="idx">
                        <td>{{ item.relationName[language] }}</td>
                        <td>
                          <div v-if="language === 'th'">
                            {{ item.account_relation_firstname_th }}
                            {{ item.account_relation_lastname_th }}
                          </div>
                          <div v-else>
                            {{ item.account_relation_firstname_en }}
                            {{ item.account_relation_lastname_en }}
                          </div>
                        </td>
                        <td>{{ item.account_relation_phone }}</td>
                        <td>{{ item.account_relation_email }}</td>
                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>fa-ellipsis-v</v-icon>
                              </v-btn>
                            </template>
                            <v-list dark>
                              <v-list-item
                                @click="() => onShowRelationModal(idx)"
                              >
                                <v-icon x-small>fa-pen</v-icon>
                                <span class="ml-3">
                                  {{ $t("normal.edit") }}
                                </span>
                              </v-list-item>
                              <template v-if="isEditable">
                                <v-list-item
                                  @click="addDeleteRelationList(item)"
                                >
                                  <v-icon x-small>fa-trash</v-icon>
                                  <span class="ml-3">
                                    {{ $t("normal.delete_list") }}
                                  </span>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5" class="w-100 text-center">No Data.</td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="mt-3 d-flex justify-content-between">
              <h4 class="text-primary">
                {{ $t("player.personal_info.education_info") }}
              </h4>
              <v-btn
                elevation="2"
                class="btn-primary"
                :disabled="!isEditable"
                @click="() => onShowEducationModal()"
              >
                + {{ $t("player.personal_info.add_education") }}
              </v-btn>
            </div>
            <div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("player.personal_info.education_level") }}
                      </th>
                      <th class="text-left">
                        {{ $t("player.personal_info.school_name") }}
                      </th>
                      <th class="text-left">
                        {{ $t("player.personal_info.faculty") }}
                      </th>
                      <th class="text-left">
                        {{ $t("player.personal_info.year_end") }}
                      </th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="education.length > 0">
                      <tr v-for="(item, idx) in education" :key="idx">
                        <td>{{ item.educationName[language] }}</td>
                        <td>
                          <div v-if="language === 'th'">
                            {{ item.account_education_name_th }}
                          </div>
                          <div v-else>
                            {{ item.account_education_name_en }}
                          </div>
                        </td>
                        <td>
                          <div v-if="language === 'th'">
                            {{ item.account_education_faculty }}
                          </div>
                          <div v-else>
                            {{ item.account_education_faculty_en }}
                          </div>
                        </td>
                        <td>
                          {{ item.account_education_start_at }} /
                          {{ item.account_education_end_at }}
                        </td>
                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>fa-ellipsis-v</v-icon>
                              </v-btn>
                            </template>
                            <v-list dark>
                              <v-list-item
                                @click="() => onShowEducationModal(idx)"
                              >
                                <v-icon x-small>fa-pen</v-icon>
                                <span class="ml-3">
                                  {{ $t("normal.edit") }}
                                </span>
                              </v-list-item>
                              <template v-if="isEditable">
                                <v-list-item
                                  @click="addDeleteEducationList(item)"
                                >
                                  <v-icon x-small>fa-trash</v-icon>
                                  <span class="ml-3">
                                    {{ $t("normal.delete_list") }}
                                  </span>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5">
                          <div class="w-100 text-center">No Data.</div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </v-form>

      <v-btn
        elevation="2"
        class="btn-primary float-right mb-5"
        v-on:click="onClickNextTab"
      >
        {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
      </v-btn>
    </div>
    <RelationModal
      :dialog.sync="showRelationModal"
      :relation="editRelation"
      :originalAddress="accountForm.account_region_citizen"
      @commit="onCommitRelation"
      :language="language"
    />
    <EducationModal
      :dialog.sync="showEducationModal"
      :education="editEducation"
      @commit="onCommitEducation"
    />
    <PermissionModal :data="permission" :dialog.sync="showPermisisonModal" />
    <SocialContactModal
      :socialContact="socialContactForm"
      @commit="onCommitSocialContact"
      :dialog.sync="showSocialContactModal"
      :language="language"
    />
  </div>
</template>

<script>
import RelationModal from "./RelationModal.vue";
import EducationModal from "./EducationModal.vue";
import PermissionModal from "./PermissionModal.vue";
import SocialContactModal from "./SocialContact.vue";
import Address from "./Address.vue";
import { differenceInYears, format } from "date-fns";
import { appAxios } from "../../helpers/app-axios";
import { countriesState } from "../../store/global_config_country";
import { languageService } from "../../helpers/language";

export default {
  components: {
    RelationModal,
    EducationModal,
    Address,
    PermissionModal,
    SocialContactModal,
  },
  name: "player-tabs1",
  props: {
    tabs: String,
  },
  mounted() {
    if (this.$route.path.includes("player")) {
      this.$data.isPlayerRoute = true;
      this.$data.isPlayerRouteName = "player";
      this.$data.accountForm.account_type = 1;
    } else {
      this.$data.accountForm.account_type = 2;
      this.$data.isPlayerRouteName = "staff";
    }
    if (!this.isAddRoute) {
      const accountId = this.$route.params.id;
      this.$data.accountId = accountId;
      this.getPermission(accountId);
      appAxios.get(`account/${accountId}`).then((res) => {
        const data = res.data?.data?.account;
        console.log("appData", data);
        this.parseAccountForm(data);
      });
      appAxios.get(`account/${accountId}/social-contacts`).then((res) => {
        const data = res.data?.data?.social_contacts;
        this.parseSocialContact(data);
      });
      appAxios.get(`account/${accountId}/relations`).then((res) => {
        const data = res.data?.data?.account_relation;
        this.parseRelation(data);
      });
      appAxios.get(`account/${accountId}/educations`).then((res) => {
        const data = res.data?.data?.account_education;
        this.parseEducation(data);
      });
      const type = this.$data.isPlayerRoute ? "players" : "staffs";
      appAxios
        .get(`account/${accountId}/medias/${type}/profile`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          if (data?.profile?.account_media_profile_avatar !== undefined) {
            this.$data.dataImage2 = data.profile.account_media_profile_avatar;
          }
        });
      const personType = this.$data.isPlayerRoute ? "player" : "staff";
      appAxios
        .get(`setting/global/positions/${personType}/all`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.positions = data;
          return;
        })
        .then(() => {
          appAxios.get(`account/${accountId}/specifications`).then((res) => {
            const data = res.data?.data?.global_config_positions?.[0];
            if (!data) return;
            const position = this.positions.find((ele) => ele.id === data);
            // this.position = position?.global_config_position_name_th;
            this.position = {
              th: position?.global_config_position_name_th,
              en: position?.global_config_position_name_en,
            };
          });
        });
    }
  },
  data() {
    return {
      language: "th",
      dataImage2: null,
      item: {
        preview: null,
        image: null,
        imageUrl: null,
      },
      isPlayerRoute: false,
      isPlayerRouteName: "",
      showRelationModal: false,
      showEducationModal: false,
      showPermisisonModal: false,
      showSocialContactModal: false,
      position: null,
      positions: [],
      gender: [
        {
          value: 0,
          text_en: this.$t("normal.male", "en"),
          text_th: this.$t("normal.male", "th"),
        },
        {
          value: 1,
          text_en: this.$t("normal.female", "en"),
          text_th: this.$t("normal.female", "th"),
        },
        {
          value: 2,
          text_en: this.$t("normal.transgender", "en"),
          text_th: this.$t("normal.transgender", "th"),
        },
      ],
      race: [],
      religion: [
        {
          text_en: "Buddhism",
          text_th: "พุทธ",
          value: "พุทธ",
        },
        {
          text_th: "คริสต์",
          text_en: "Christianity",
          value: "คริสต์",
        },
        {
          text_th: "อิสลาม",
          text_en: "Islam",
          value: "อิสลาม",
        },
        {
          text_th: "ฮินดู",
          text_en: "Hinduism",
          value: "ฮินดู",
        },
        {
          text_th: "อื่น ๆ",
          text_en: "Other",
          value: "อื่น ๆ",
        },
      ],
      nationality: [
        { text: "ไทย", value: "ไทย" },
        { text: "อังกฤษ", value: "อังกฤษ" },
        { text: "อื่น ๆ", value: "อื่น ๆ" },
      ],
      relation: [],
      deleteRelationList: [],
      education: [],
      deleteEducationList: [],
      editEducation: null,
      editEducationIdx: null,
      editRelation: null,
      editRelationIdx: null,
      maxSelectDate: format(new Date(), "yyyy-MM-dd"),
      accountForm: {
        account_information_code_personnel: null,
        account_information_citizen_id: null,
        account_information_passport_id: null,
        account_information_type_personnel: "THAI",
        account_information_firstname_th: null,
        account_information_lastname_th: null,
        account_information_firstname_en: null,
        account_information_lastname_en: null,
        account_information_nickname: null,
        account_information_nickname_en: null,
        account_information_blood_type: null,
        account_information_dob: null,
        account_information_gender: null,
        account_information_religion: null,
        account_information_race: "ไทย",
        account_information_nationality: "ไทย",
        account_region_citizen: {
          country_id: "188",
        },
        account_region_current: {
          country_id: "188",
        },
        account_type: 1,
        active: false,
        createdAt: null,
        updatedAt: null,
        updatedBy: null,
      },
      accountId: null,
      socialContactForm: {
        account_information_phone: null,
        account_social_contact_email: null,
        account_social_contact_facebook: null,
        account_social_contact_line: null,
        account_social_contact_twitter: null,
        account_social_contact_instagram: null,
        account_social_contact_youtube: null,
        account_social_contact_website: null,
        account_social_contact_fax: null,
      },
      templateEmail: null,
      permission: {
        email: "",
        permission: "Default",
      },
    };
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  computed: {
    label() {
      return this.$data.isPlayerRoute
        ? {
            // name: this.fullname || "ชื่อ - นามสกุล",
            name: this.fullname || {
              en: "Name",
              th: "ชื่อ - นามสกุล",
            },
            position: this.position || {
              en: "Position",
              th: "ตำแหน่งนักกีฬา",
            },
            // position: this.position || "ตำแหน่งนักกีฬา",
            accountCode: {
              th: "รหัสประจำตัวนักกีฬา",
              en: "Athelete ID",
            },
            // type: "ประเภทนักกีฬา",
            type: {
              th: "ประเภทนักกีฬา",
              en: "Athlete Type",
            },
            thai: {
              th: "นักกีฬาไทย",
              en: "Thai Athlete",
            },
            foregin: {
              th: "นักกีฬาต่างชาติ",
              en: "Foreign Athlete",
            },
          }
        : {
            name: this.fullname || {
              en: "Name",
              th: "ชื่อ - นามสกุล",
            },
            position: this.position || {
              en: "Position",
              th: "ตำแหน่งบุคลากร",
            },
            accountCode: {
              th: "รหัสประจำตัวบุคลากร",
              en: "Staff ID",
            },
            type: {
              th: "ประเภทบุคลากร",
              en: "Staff Type",
            },
            thai: {
              th: "บุคลากรไทย",
              en: "Thai Staff",
            },
            foregin: {
              th: "บุคลากรต่างชาติ",
              en: "Foreign Staff",
            },
          };
    },
    fullname() {
      const firtname =
        this.accountForm.account_information_firstname_th !== null &&
        this.accountForm.account_information_firstname_th !== ""
          ? this.accountForm.account_information_firstname_th
          : "";
      const lastname =
        this.accountForm.account_information_lastname_th !== null &&
        this.accountForm.account_information_lastname_th !== ""
          ? this.accountForm.account_information_lastname_th
          : "";

      const firtnameEn =
        this.accountForm.account_information_firstname_en !== null &&
        this.accountForm.account_information_firstname_en !== ""
          ? this.accountForm.account_information_firstname_en
          : "";
      const lastnameEn =
        this.accountForm.account_information_lastname_en !== null &&
        this.accountForm.account_information_lastname_en !== ""
          ? this.accountForm.account_information_lastname_en
          : "";
      // return this.accountForm.account_information_firstname_th
      // 	? `${this.accountForm.account_information_firstname_th} ${this.accountForm.account_information_lastname_th}`
      // 	: "";
      // return firtname + " " + lastname;
      return {
        en: firtnameEn + " " + lastnameEn,
        th: firtname + " " + lastname,
      };
    },
    countries() {
      return this.$store.state.globalConfigCountry[countriesState];
    },
    age() {
      if (!this.$data.accountForm.account_information_dob) {
        return 0;
      }
      return differenceInYears(
        new Date(),
        new Date(this.$data.accountForm.account_information_dob)
      );
    },
    birthDayFormat() {
      if (!this.$data.accountForm.account_information_dob) {
        return null;
      }

      return format(
        new Date(this.$data.accountForm.account_information_dob),
        "dd/MM/yy"
      );
    },
    citizenRule() {
      const rules = [];
      if (
        this.$data.accountForm.account_information_type_personnel === "THAI" &&
        this.language === "th"
      ) {
        const idRegex = new RegExp(/^[0-9]{13}$/);
        rules.push((v) => !!v || `กรุณาระบุเลขบัตรประชาชน`);
        rules.push(
          (v) => idRegex.test(v) || `เลขบัตรประชาชนประกอบด้วยตัวเลข 13 หลัก`
        );
      } else if (
        this.$data.accountForm.account_information_type_personnel === "THAI" &&
        this.language === "en"
      ) {
        const idRegex = new RegExp(/^[0-9]{13}$/);
        rules.push((v) => !!v || `Please enter your ID Card`);
        rules.push(
          (v) => idRegex.test(v) || `Citizen ID must be contain 13 number`
        );
      }

      return rules;
    },
    passportRules() {
      const rules = [];
      if (
        this.$data.accountForm.account_information_type_personnel === "FOREIGN"
      ) {
        if (this.language === "th") {
          rules.push((v) => !!v || `กรุณาระบุหมายเลขพาสปอร์ต`);
        } else {
          rules.push((v) => !!v || `Please enter your Passport Card`);
        }
      }

      return rules;
    },
    isEditable() {
      if (this.$data.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    isAddRoute() {
      return this.$route.path.includes("add");
    },
  },
  methods: {
    copyAddressToCurrent() {
      if (!this.isEditable) return;
      this.$data.accountForm.account_region_current = {
        ...this.$data.accountForm.account_region_citizen,
      };
    },
    addDeleteRelationList(relationItem) {
      const relationId = relationItem.id;
      this.$data.relation.splice(relationItem.idx, 1);
      if (!relationId) {
        return;
      }
      this.deleteRelationList.push(relationId);
    },
    addDeleteEducationList(educationItem) {
      const educationId = educationItem.id;
      this.$data.education.splice(educationItem.idx, 1);
      if (!educationId) {
        return;
      }
      this.deleteEducationList.push(educationId);
    },
    async onClickNextTab() {
      const accountId = await this.save(false);
      if (!accountId) return;
      return this.$router
        .push({
          path: `/${
            this.$data.isPlayerRoute ? "player" : "staff"
          }/edit/${accountId}`,
          query: { tab: 1 },
        })
        .catch(() => {});
    },
    async getPermission(accountId) {
      if (!accountId) {
        return;
      }
      return await appAxios
        .get(`account/${accountId}/roles-permissions/big-data`)
        .then((res) => {
          const data = res.data?.data?.account;
          if (!data) return;
          this.$data.templateEmail = data.account_email;
          this.$data.permission = {
            email: data.account_email,
            permission: data.permission.permission_name_en,
          };
        });
    },
    async save(isRedirect = true) {
      if (!this.isEditable) return;
      const result = this.$refs.formRef.validate();
      if (!result) return;
      // console.log('test')
      // return true
      const accountId = await this.savePersonnels();
      await this.saveEducation(accountId);
      await this.savePermission(accountId);
      await this.saveRelation(accountId);
      await this.savePublicPersonal(accountId);
      await this.saveSocialContact(accountId);
      if (this.$data.item.image !== null) {
        await this.saveImageProfile(accountId);
      }
      await this.deleteRelation(accountId);
      await this.deleteEducation(accountId);
      if (this.$route.path.includes("add") && isRedirect) {
        this.$data.accountForm.accountId = accountId;
        this.$router
          .push({
            path: `/${
              this.$data.isPlayerRoute ? "player" : "staff"
            }/edit/${accountId}`,
            query: { tab: 0 },
          })
          .catch(() => {});
        return;
      }
      return accountId;
    },
    parseSocialContact(data) {
      if (!data) return;
      this.$data.socialContactForm = data;
    },
    parseAccountForm(data) {
      const information = data.account_informations;
      const currentAddress = data.account_region_current || {};
      const citizenAddress = data.account_region_citizen || {};

      this.$data.accountForm = {
        accountId: data.account_id,
        account_information_code_personnel:
          information.account_information_code_personnel,
        account_information_citizen_id:
          information.account_information_citizen_id,
        account_information_passport_id:
          information.account_information_passport_id,
        account_information_type_personnel:
          information.account_information_type_personnel,
        account_information_firstname_th:
          information.account_information_firstname_th,
        account_information_lastname_th:
          information.account_information_lastname_th,
        account_information_firstname_en:
          information.account_information_firstname_en,
        account_information_lastname_en:
          information.account_information_lastname_en,
        account_information_nickname: information.account_information_nickname,
        account_information_nickname_en:
          information.account_information_nickname_en,
        account_information_blood_type:
          information.account_information_blood_type,
        account_information_dob:
          information.account_information_dob === null
            ? null
            : format(
                new Date(information.account_information_dob),
                "yyyy-MM-dd"
              ),
        account_information_gender: information.account_information_gender,
        account_information_religion: information.account_information_religion,
        account_information_race: information.account_information_race,
        account_information_nationality:
          information.account_information_nationality,
        account_region_citizen: {
          country_id: citizenAddress?.account_region_country_id,
          address_detail: citizenAddress?.account_region_address_detail,
          address_detail_en: citizenAddress?.account_region_address_detail_en,
          province_id: citizenAddress?.account_region_province_id,
          district_id: citizenAddress?.account_region_district_id,
          subdistrict_id: citizenAddress?.account_region_subdistrict_id,
          zipcode: citizenAddress?.account_region_zipcode,
        },
        account_region_current: {
          country_id: currentAddress.account_region_country_id,
          address_detail: currentAddress.account_region_address_detail,
          address_detail_en: currentAddress.account_region_address_detail_en,

          province_id: currentAddress.account_region_province_id,
          district_id: currentAddress.account_region_district_id,
          subdistrict_id: currentAddress.account_region_subdistrict_id,
          zipcode: currentAddress.account_region_zipcode,
        },
        account_type: data.account_type,
        active: data.account_published,
        createdAt: format(new Date(data.created_at), "dd/MM/yyyy"),
        updatedAt: format(new Date(data.updated_at), "dd/MM/yyyy"),
        updatedBy: data.updatedBy,
      };
    },
    parseRelation(data) {
      this.$data.relation = data.map((ele, idx) => ({
        idx,
        id: ele.id,
        global_config_relations: ele.global_config_relations.id,
        account_relation_firstname_th: ele.account_relation_firstname_th,
        account_relation_lastname_th: ele.account_relation_lastname_th,
        account_relation_firstname_en: ele.account_relation_firstname_en,
        account_relation_lastname_en: ele.account_relation_lastname_en,
        account_relation_phone: ele.account_relation_phone,
        account_relation_email: ele.account_relation_email,
        account_relation_address: ele.account_relation_address,
        account_relation_address_en: ele.account_relation_address_en,
        relationName: {
          en: ele.global_config_relations.global_config_relation_name_en,
          th: ele.global_config_relations.global_config_relation_name_th,
        },
        account_relation_country_id: ele.account_relation_country_id,
        account_relation_province_id: ele.account_relation_province_id,
        account_relation_district_id: ele.account_relation_district_id,
        account_relation_subdistrict_id: ele.account_relation_subdistrict_id,
        account_relation_zipcode: ele.account_relation_zipcode,
      }));
    },
    parseEducation(data) {
      this.$data.education = data.map((ele, idx) => ({
        idx,
        id: ele.id,
        educationName: {
          th: ele.global_config_educations?.global_config_education_name_th,
          en: ele.global_config_educations?.global_config_education_name_en,
        },
        global_config_educations: ele.global_config_educations?.id,
        account_education_name_th: ele.account_education_name_th,
        account_education_name_en: ele.account_education_name_en,
        account_education_faculty: ele.account_education_faculty,
        account_education_faculty_en: ele.account_education_faculty_en,
        account_education_start_at: +ele.account_education_start_at,
        account_education_end_at: +ele.account_education_end_at,
        account_education_current: ele.account_education_current,
      }));
    },
    async savePublicPersonal(accountId) {
      const publics = {
        account_published: this.$data.accountForm.active,
      };
      return appAxios
        .post(`account/${accountId}/published/update`, publics)
        .then((res) => {
          res;
        });
    },
    async saveImageProfile(accountId) {
      const type = this.$data.isPlayerRoute ? "players" : "staffs";
      let formData = new FormData();
      formData.append("file", this.$data.item.image);
      appAxios
        .post(`account/${accountId}/medias/${type}/profile`, formData)
        .then((res) => res);
    },
    async saveRelation(accountId) {
      const body = this.$data.relation.map((ele) => {
        const relation = ele;
        delete relation.relationName;
        delete relation.idx;
        return relation;
      });
      if (body.length === 0) {
        return;
      }
      return appAxios
        .post(`account/${accountId}/relations`, body)
        .then((res) => this.parseRelation(res.data.data.account_relation));
    },
    async deleteRelation(accountId) {
      return await Promise.all(
        this.$data.deleteRelationList.map(async (ele) => {
          return await appAxios
            .post(`account/${accountId}/relations/${ele}/delete`)
            .then((res) => res);
        })
      );
    },
    async savePermission(accountId) {
      if (
        !this.$data.permission.email ||
        this.$data.permission.email === this.templateEmail
      )
        return;
      const payload = {
        account_email: this.$data.permission.email,
      };
      return await appAxios
        .post(`account/${accountId}/create/authentication`, payload)
        .then((res) => res);
    },
    async saveEducation(accountId) {
      const body = this.$data.education.map((ele) => {
        const education = ele;
        delete education.educationName;
        delete education.idx;
        return education;
      });
      if (body.length === 0) {
        return;
      }
      return appAxios
        .post(`account/${accountId}/educations`, body)
        .then((res) => {
          this.parseEducation(res.data.data.account_education);
        });
    },
    async deleteEducation(accountId) {
      return await Promise.all(
        this.$data.deleteEducationList.map(async (ele) => {
          return await appAxios
            .post(`account/${accountId}/educations/${ele}/delete`)
            .then((res) => res);
        })
      );
    },
    async savePersonnels() {
      const nationalityEn = this.countries.find(
        (c) =>
          c.country_native1_name_th ===
          this.$data.accountForm.account_information_nationality
      );
      const body = {
        ...this.$data.accountForm,
        account_information_nationality_en:
          nationalityEn?.country_native1_name_en,
        account_region_citizen: {
          account_region_country_id:
            this.$data.accountForm.account_region_citizen.country_id ===
              undefined ||
            this.$data.accountForm.account_region_citizen.country_id === null
              ? null
              : +this.$data.accountForm.account_region_citizen.country_id,
          account_region_address_detail:
            this.$data.accountForm.account_region_citizen.address_detail,
          account_region_address_detail_en:
            this.$data.accountForm.account_region_citizen.address_detail_en,
          account_region_province_id:
            this.$data.accountForm.account_region_citizen.province_id ===
              undefined ||
            this.$data.accountForm.account_region_citizen.province_id === null
              ? null
              : +this.$data.accountForm.account_region_citizen.province_id,
          account_region_district_id:
            this.$data.accountForm.account_region_citizen.district_id ===
              undefined ||
            this.$data.accountForm.account_region_citizen.district_id === null
              ? null
              : +this.$data.accountForm.account_region_citizen.district_id,
          account_region_subdistrict_id:
            this.$data.accountForm.account_region_citizen.subdistrict_id ===
              undefined ||
            this.$data.accountForm.account_region_citizen.subdistrict_id ===
              null
              ? null
              : +this.$data.accountForm.account_region_citizen.subdistrict_id,
          account_region_zipcode:
            this.$data.accountForm.account_region_citizen.zipcode,
        },
        account_region_current: {
          account_region_country_id:
            this.$data.accountForm.account_region_current.country_id ===
              undefined ||
            this.$data.accountForm.account_region_current.country_id === null
              ? null
              : +this.$data.accountForm.account_region_current.country_id,
          account_region_address_detail:
            this.$data.accountForm.account_region_current.address_detail,
          account_region_address_detail_en:
            this.$data.accountForm.account_region_current.address_detail_en,
          account_region_province_id:
            this.$data.accountForm.account_region_current.province_id ===
              undefined ||
            this.$data.accountForm.account_region_current.province_id === null
              ? null
              : +this.$data.accountForm.account_region_current.province_id,
          account_region_district_id:
            this.$data.accountForm.account_region_current.district_id ===
              undefined ||
            this.$data.accountForm.account_region_current.district_id === null
              ? null
              : +this.$data.accountForm.account_region_current.district_id,
          account_region_subdistrict_id:
            this.$data.accountForm.account_region_current.subdistrict_id ===
              undefined ||
            this.$data.accountForm.account_region_current.subdistrict_id ===
              null
              ? null
              : +this.$data.accountForm.account_region_current.subdistrict_id,
          account_region_zipcode:
            this.$data.accountForm.account_region_current.zipcode,
        },
      };
      delete body.updatedAt;
      delete body.updatedBy;
      delete body.createdAt;
      // save personels

      if (body.accountId) {
        const accountId = body.accountId;
        delete body.accountId;
        return await appAxios
          .post(`account/personels/${accountId}/update`, body)
          .then((res) => {
            return res.data?.data?.account?.account_id;
          });
      } else {
        return await appAxios.post("account/personnels", body).then((res) => {
          return res.data?.data?.account?.account_id;
        });
      }
    },
    async saveSocialContact(accountId) {
      const body = this.$data.socialContactForm;
      appAxios
        .post(`account/${accountId}/social-contacts`, body)
        .then((res) => res);
    },
    onCommitSocialContact(value) {
      this.$data.socialContactForm = value;
    },
    onCommitEducation(value) {
      if (this.$data.editEducation) {
        this.education[this.$data.editEducationIdx] = value;
        return;
      }
      this.$data.education.push(value);
    },
    onCommitRelation(value) {
      if (this.$data.editRelation) {
        this.relation[this.$data.editRelationIdx] = value;
        return;
      }
      this.$data.relation.push({
        ...value,
        idx: this.$data.relation.length,
      });
    },
    onClickPermission() {
      this.$data.showPermisisonModal = true;
    },
    onClickSocialContact() {
      // console.log(this.$data.showSocialContactModal);
      this.$data.showSocialContactModal = true;
    },
    onShowRelationModal(idx) {
      this.$data.editRelation = null;
      this.$data.editRelationIdx = null;
      if (typeof idx === "number") {
        this.$data.editRelationIdx = idx;
        this.$data.editRelation = this.$data.relation[idx];
      }
      this.showRelationModal = true;
    },
    onShowEducationModal(idx) {
      this.$data.editEducation = null;
      this.$data.editEducationIdx = null;
      if (typeof idx === "number") {
        this.$data.editEducationIdx = idx;
        this.$data.editEducation = this.$data.education[idx];
      }
      this.showEducationModal = true;
    },
    onChangeImage(e) {
      const file = e.target.files[0];

      this.$data.item.preview = e;
      this.$data.item.image = file;
      this.$data.item.imageUrl = URL.createObjectURL(file);
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.v-input--selectin-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.active-radio .v-icon {
  color: red;
}

@media (min-width: 960px) {
  .tab1-border-left {
    border-left: 1px solid #ebe9f1 !important;
  }
}

.image-upload .uploadImage {
  position: absolute;
  right: 20%;
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}

.profileImage {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.textWord {
  word-break: break-word;
}
</style>
